.swiper1 {
	max-width: 100vw;
	overflow: hidden;
	//max-width: 100%;
	.swiper-slide {
		height: 180px !important;
		width: 90vw;
		//padding: 12px 0;
		margin-top: 30px;
	}

	//@media(min-width: 1280px) {
	//	max-width: 705px;
	//	.swiper-slide {
	//		width: 293px;
	//	}
	//}
	//
	.swiper-slide_inner {
		height: 100%;
		background: #777;;
		background: #f9f9f9;
		display: grid;
		grid-template-rows: 44px 1fr;
		align-items: start;
		overflow-y: auto;
		padding-bottom: 10px;
	}
	
	.swiper-slide.swiper-slide-active {
		padding: 2px 0;
		position: relative;
		z-index: 100 !important;
	}
}

.testimonials {
	position: relative;
	.swiper-pagination {
	left: 50%;
	transform: translateX(-50%);
  }
}

.person {
	grid-template-columns: 40px 1fr;
	justify-content: start;
}

.swiper2 {
	overflow: hidden;
	margin-left: auto;
	margin-right: auto;
}

.swiper2-pagination {
	left: 50%;
	transform: translateX(-50%);
}

.swiper-pagination-clickable .swiper-pagination-bullet {
	width: 4px;
	height: 4px;
	&.swiper-pagination-bullet-active {
		width: 6px;
		height: 6px;
	}
}

@media(min-width: 1280px){
.mask1 {
	z-index: 5;
	position: absolute;
	top: 0;
	left: 0;
	right: 85%;
	bottom: 0;
  background: linear-gradient(90deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0) 100%);
 }

.mask2 {
	z-index: 5;
	position: absolute;
	top: 0;
	left: 85%;
	right: 0;
	bottom: 0;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
 }
}