@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

$backgroundColor: #ffffff;
$boxBackground: #f9f9f9;
$borderColor: #cccccc;
$bodyColor: rgba(28, 28, 28, 0.6);
$headersColor: #1c1c1c;
$textColor: #474747;
$bodyFont: Inter, apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";


.article_container {
  margin-top: 50px;
  display: grid;
  grid-column-gap: 50px;
  .article_content_box {
    color: $textColor;
    overflow: auto; //
  }
  .article_right_sidebar {
    width: 400px;
    color: $headersColor;
    header {
      color: $bodyColor;
    }
    margin-left: 40px;
    margin-bottom: 20px;
    z-index: 10;
  }
}

.driver {
  p {
    margin-bottom: 16px;
    font-size: 14px;
  }
  @media(min-width: 1280px) {
    grid-template-columns: 344px 1fr; // product_menu size
  }
  @media(min-width: 1480px) {
    grid-template-columns: 420px 1fr; // product_menu size
  }
  @media(max-width: 850px) {
    .article_right_sidebar {
      width: 100%; // key_features to all screen
    }
  }
}

//to address sidebar padding on mobile
@media (max-width: 850px) {
  .sidebar_left,
  .pc_content,
  .contact_form,
  .sk-ww-linkedin-profile-post {
    padding-right: 0.0rem;
  }

  .swiper_outer {
    width: 95vw;
  }
}

.horizontal_size {
  max-width: 1180px;
  padding-left: 1rem; //to have at least some margins for small screen
  padding-right: 1rem;
  margin-left: auto; // to center the content
  margin-right: auto;
  @media(min-width: 1480px) {
    max-width: 1380px;
  }
}

.sticky_menu {
  @media (min-width: 1280px) {
    position: sticky;
  }
  top: 90px;
}


.additions_content {
   display: grid;
   grid-gap: 20px;
   @media (min-width: 850px) {
     grid-template-columns: repeat(2, minmax(0, 1fr));
   }
}

.home {
  grid-row-gap: 50px;
  @media (min-width: 850px) {
    grid-template-columns: minmax(0, 1fr) 344px; // product_menu size
    grid-template-rows: auto auto auto 1fr;
  }
}

.news {
  @apply max-w-full sticky top-0; //xl:w-[450px] 2xl:w-[525px]
  @media (min-width: 850px) {
    grid-column: 2;
    grid-row: 1 / span 4;
  }
}

//
//.article_inner {
//  padding-left: 1rem;
//  padding-right: 1rem;
//}

//.article_content_box {
//  display: flex;
//  flex-wrap: wrap;
//  gap: 20px; /* Adjust the value as needed */
//}


html {
  scroll-behavior: smooth;
  box-sizing: border-box;
  color: $bodyColor;
  scrollbar-width: thin;
  background: $backgroundColor;
  font-family: $bodyFont;
  body * {
    line-height: 1.2;
    position: relative;
  }
}

h1,h2 {
  color: $headersColor;
}

html,
body {
  position: relative;
  height: 100%;
}

p,
div,
span,
a {
  font-size: 14px;
  margin: 0;
}

article h1 {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 30px;

  @media(min-width: 1280px) {
    font-size: 36px;
  }
}

article h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 28px;
  margin-top: 34px;

  @media(min-width: 1280px) {
    font-size: 28px;
  }
}

article ul li {
  margin-bottom: 6px;
  list-style-type: disc;
  margin-left: 24px;
}

h2 {
  font-size: 36px;
  font-weight: 600;
  @apply text-2xl md:text-4xl pb-5;
}

[id] {
  scroll-margin-top: 80px;
}

.hero {
  display: grid;
  background-image: url(/assets/images/hero.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  .hero_nav,
  .description {
    backdrop-filter: blur(12px);
    border: 1px solid #FFFFFF1A;
  }

  .hero_inner {
    color: #fff;
    .hero_nav,
    .text_box {
      line-height: 1.2;
      .description {
        font-size: 14px;
      }
    }
  }

  @media (orientation: landscape) {
    background-size: 100% 100%;
  }
}

.box {
  background: $boxBackground;
  border-color: $borderColor;
}

//.home_content {
//  display: grid;
//  max-width: 1180px;
//  padding-left: 1.25rem;
//  padding-right: 1.25rem;
//
//  @media(min-width: 1480px) {
//    max-width: 1380px;
//  }
//
//  @media(min-width: 1280px) {
//    grid-template-columns: 1fr auto;
//    grid-column-gap: 100px;
//  }
//}


.heading svg {
  display: block;
  margin: auto;
}

div.linkedin-profile-post-user-root-container {
  display: none;
}

div.sk-post-text-new {
  color: #474747;
}

header.flex.justify-between.items-center.mb-5.flex-wrap {
  margin-bottom: 0.5rem;
}

.linkedin-post .grid-content {
  background: $boxBackground !important;
  border: 1px solid $borderColor !important;
  border-radius: 6px;
  padding: 12px !important;
}

.linkedin-profile-post-user-container.sk-follow-linkedin-btn {
  height: fit-content !important;
  background-color: #0f87dd !important;
}

.contact {
  color: $headersColor;
}

.contact_form textarea,
.contact_form input {
  padding: 11px 14px;
  border-radius: 3px;
}

input, textarea {
  background: $boxBackground;
  border: 1px solid $borderColor;
}

.contact_form button {
  background: #0F87DD;
  color: white;
  padding: 11px 21px;
  border-radius: 3px;
  display: flex;
  gap: 10px;
  width: fit-content;
}

.address_card {
  grid-template-columns: 24px 1fr;

  svg {
    width: 24px;
    height: auto;
  }

  a {
    text-decoration: underline;
  }
}

.search-left {
  letter-spacing: -4%;
}

.page_features {
  padding: 0 10px;
  ul li {
    list-style: disc;
    line-height: 1.2;
    font-size: 14px;
  }
}

.page_info {
  padding: 0 10px;
  ul li {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: center;
    padding: 4px;
    margin: 0 0 8px;
    border: 1px solid #494949;
    border-radius: 4px;

    &::after {
      content: url('/assets/images/arrow-circle-up-right.svg');
      display: inline-block;
      width: 14px;
      height: 14px;
    }
  }
}

.md-table .row_results td {
  border-bottom: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}

.page_models_table {
  padding-left: 20px;
  padding-right: 20px;
  table {
    width: 100%;

    tr {
      flex-wrap: wrap;
    }

    td {
      border-bottom: 1px solid #ccc;
      padding: 5px;
      text-align: center;
    }
  }
}

.article_container iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 720 / 405;
}

.active a {
  color: darkblue;
}

.menu_opener::before {
  content: url(/assets/images/arrow_right.svg);
  display: inline-block;
  margin-right: 2px;
  width: 12px;
  height: 12px;
}

.menu_opener.opened::before {
  content: url(/assets/images/arrow_bottom.svg);
}

.nav_item * {
  line-height: 1;
}

.sidebar_left .grid {
  background: $boxBackground;
  border-color: $borderColor;
  ul li {
    list-style-type: none;
  }
}

.additions {
  .zoom_off {
    position: relative;
    width: fit-content;
    height: fit-content;
    @media(min-width: 1024px){
    &::before {
      content: url(/assets/images/expand.svg);
      position: absolute;
      top: 13px;
      right: 23px;
      width: 15px;
      height: auto;
      z-index: 10;
      cursor: zoom-in;
      }

      &.zoom::before {
        cursor: zoom-out;
      }
    }
  }

  .zoom.zoom_off {
    z-index: 20;
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(200, 200, 200, 0.8);
  }

.zoom_off img {
    padding: 30px 10px 10px;
    border: 1px solid $borderColor;
    border-radius: 4px;
    max-width: 100%;
    background: $backgroundColor;
  }

  .zoom img {
    z-index: 25;
    position: fixed;
    max-height: 90%;
    max-width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe {
    width: 100%;
    aspect-ratio: 720 / 405;
    }

  //@media(min-width: 1280px){
  //  grid-template-columns: 344px 786px;
  //  .additions_content {
  //    width: 936px;
  //  }
  //}
}

.full_content {
  //padding-bottom: 40px;
}

.home .main_menu {
  display: none;
}

.article_content .md-table {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid $borderColor;
  border-radius: 8px;
  tr {
    td {
      padding: 4px 6px;
    }

    &:nth-child(2n) {
      background-color: #e4e4e4;
    }
  }
}

.page_models_table tr,
.page_models_table tbody {
  flex-wrap: wrap;
}

.row_results {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
}

.sk-popup-post-content {
  height: 500px;
  overflow-y: auto !important;
}

.sk-popup-post-content-details {
  row-gap: 8px;
}

.post-post-counts {
  margin-top: 60px !important;
}

body .mfp-content .sk_post_media {
  background: #fff !important;
}

.sk_meta_holder {
  display: none;
}

.text_expander { 
  margin: 16px 0;
  svg {
  width: 14px;
  height: 14px;
  display: inline-block;
 }
}

.back_link {
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.use_case_card,
.swiper-slide {
  color: $textColor;
}

//Newsfeed styles

//forces newsfeed to be full width on desktop
@media (min-width: 1280px) {
  .grid-item-linkedin-profile-post {
    width: 100% !important;
  }
}

//hides profile name
body .sk-ww-linkedin-profile-post .sk-fb-profile-name a {
  display: none;
}

//hides profile image
body .sk-ww-linkedin-profile-post .post-image {
  display: none;
}

//fixes margin on post counts
.post-post-counts {
  margin-top: 10px !important;
}

//hides favorite count
body .sk-ww-linkedin-profile-post .sk-post-favorite-count {
  display: none;
}

//hides repost count
body .sk-ww-linkedin-profile-post .sk-post-repost-count {
  display: none;
}

//fixes font size and family of post text
body .sk-ww-linkedin-profile-post .sk-post-text-new {
  font-size: 14px !important;
  font-family: $bodyFont !important;
  color: $textColor !important;
}

//fixes font size and family of app-aware link
.app-aware-link {
  font-size: 14px !important;
  font-family: $bodyFont !important;
  color: $textColor !important;
  text-decoration: underline !important;
}

//fixes color of link
body .sk-ww-linkedin-profile-post .margin-zero a {
  color: $textColor !important;
}

//fixes margin on newsfeed header
header.flex.justify-between.items-center.mb-5.flex-wrap {
  margin-bottom: -.5rem;
}